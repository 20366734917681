export const PISADA_NEUTRA = 'neutra'
export const PISADA_SUPINADA = 'supinada'
export const PISADA_PRONADA = 'pronada'
export const PISADAS = [PISADA_NEUTRA, PISADA_PRONADA, PISADA_SUPINADA]

export const TIPO_TESTE_PISADA = 'teste_pisada'
export const TIPO_TESTE_NUMERACAO = 'teste_numeracao'
export const TIPOS_TESTE = [TIPO_TESTE_PISADA, TIPO_TESTE_NUMERACAO]
export const TIPOS_TESTE_LABEL = {}
TIPOS_TESTE_LABEL[TIPO_TESTE_PISADA] = 'Teste Pisada'
TIPOS_TESTE_LABEL[TIPO_TESTE_NUMERACAO] = 'Teste Numeração'
