import React, { useEffect, useRef, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import cx from 'classnames'
import { toast, ToastContainer } from 'react-toastify'
import CircularProgress from '@material-ui/core/CircularProgress'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Label from '../../components/Form/label'
import Input from '../../components/Form/input'
import InputMask from '../../components/Form/mask'
import Select from '../../components/Form/select'
import Option from '../../components/Form/option'
import ErrorMessage from '../../components/ErrorMessage'
import Button from '../../components/Buttons/button'
import BannerFootID from '../../components/FootId/Banner'
import {
  getData,
  limpaObjeto,
  maskTelefone,
  storeData,
} from '../../helpers/util'
import {
  PISADAS,
  TIPO_TESTE_NUMERACAO,
  TIPO_TESTE_PISADA,
  TIPOS_TESTE,
  TIPOS_TESTE_LABEL,
} from '../../constants/footId'
import { GENEROS } from '../../constants/form'
import { ditoIdentify } from '../../helpers/dito'
import {
  fetchData,
  requestUpload,
  URL_AGENDAMENTO,
  URL_FILIAIS,
  URL_FILIAIS_QUICK,
  URL_RESULTADO,
} from '../../services/API'

import bannerVendedor from '../../assets/images/foot-id/banner-vendedor.jpg'

import * as S from './styles'
import { useI18next } from 'gatsby-plugin-react-i18next'

const config = {
  headers: {
    Accept: 'application/json',
  },
}

const VendedorFootIdPage = () => {
  const { language } = useI18next()
  const [idCliente, setIdCliente] = useState('')
  const [dadosCliente, setDadosCliente] = useState({
    nome: '',
    email: '',
    genero: '',
    data_nascimento: '',
    telefone: '',
    numero_calcado: '',
  })
  const [tipoTeste, setTipoTeste] = useState('')
  const [codigoVendedor, setCodigoVendedor] = useState('')
  const [peEsquerdo, setPeEsquerdo] = useState('')
  const [peDireito, setPeDireito] = useState('')
  const [listaFiliais, setListaFiliais] = useState([])
  const [listaFiliaisQuick, setListaFiliaisQuick] = useState([])
  const [listaFiliaisUtilizada, setListaFiliaisUtilizada] = useState([])
  const [filialId, setFilialId] = useState(undefined)
  const [desconto, setDesconto] = useState(null)
  const [anexo, setAnexo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingCliente, setLoadingCliente] = useState(false)
  const [erroCampos, setErroCampos] = useState({})

  const hiddenFileInput = useRef(null)

  useEffect(() => {
    async function pegaFiliais(quick = false) {
      const { data, error } = await fetchData(
        quick ? URL_FILIAIS_QUICK(language) : URL_FILIAIS(language)
      )

      if (error) {
        toast.error(error.message)
        return
      }
      if (quick) {
        setListaFiliaisQuick(data)
      } else {
        setListaFiliais(data)
      }
    }

    pegaFiliais()
    pegaFiliais(true)
  }, [])

  useEffect(() => {
    if (listaFiliais.length > 0 && listaFiliaisQuick.length > 0) {
      const tipoTesteGuardado = getData('tipoTeste')
      if (tipoTesteGuardado) {
        setTipoTeste(tipoTesteGuardado)
      }
    }
  }, [listaFiliais, listaFiliaisQuick])

  useEffect(() => {
    const filialIdGuardada = getData('filialId')
    if (
      filialIdGuardada &&
      listaFiliaisUtilizada.find(({ id }) => id === filialIdGuardada)
    ) {
      setFilialId(filialIdGuardada)
    }
  }, [listaFiliaisUtilizada])

  useEffect(() => {
    if (filialId !== undefined) {
      const pegaDescontoFilial = listaFiliaisUtilizada.filter(
        (filial) => filial.id === filialId
      )

      if (pegaDescontoFilial.length > 0) {
        setDesconto(pegaDescontoFilial[0].foot_id_desconto)
      }

      storeData('filialId', filialId)
    }
  }, [listaFiliaisUtilizada, filialId])

  useEffect(() => {
    let novaListaFiliaisUtilizada = []
    switch (tipoTeste) {
      case TIPO_TESTE_PISADA:
        novaListaFiliaisUtilizada = listaFiliais
        break
      case TIPO_TESTE_NUMERACAO:
        novaListaFiliaisUtilizada = listaFiliaisQuick
        break
    }
    setListaFiliaisUtilizada(novaListaFiliaisUtilizada)
    if (!listaFiliaisUtilizada.includes(filialId)) {
      setFilialId(undefined)
    }
  }, [tipoTeste])

  async function handleBuscarCliente() {
    if (idCliente === '') {
      toast.error('Adicione o ID do cliente')
      return
    }

    setLoadingCliente(true)

    try {
      setLoadingCliente(true)
      const response = await fetch(
        `${URL_AGENDAMENTO(language)}/${idCliente}`,
        config
      )
      const { data } = await response.json()

      if (!response.ok && response.status === 404) {
        toast.error('Cliente não cadastrado')
        return
      }

      if (!response.ok) {
        throw new Error(data.message)
      }

      const objCliente = limpaObjeto(data)

      setDadosCliente({ ...objCliente })

      if (listaFiliais.find((loja) => loja.id === data.loja_id)) {
        setFilialId(data.loja_id)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoadingCliente(false)
    }
  }

  function handleChangeForm(event) {
    const value = event.target.value

    setDadosCliente({
      ...dadosCliente,
      [event.target.name]: value,
    })
  }

  function handleChangeFormTipoTeste({ target }) {
    setTipoTeste(target.value)
    setPeEsquerdo('')
    setPeDireito('')
    storeData('tipoTeste', target.value)
  }

  async function handleSubmit(event) {
    event.preventDefault()

    const formData = new FormData()

    const dados = {
      ...dadosCliente,
      tipo_teste: tipoTeste,
      loja_id: filialId,
      codigo_vendedor: codigoVendedor,
      pisada_pe_esquerdo: peEsquerdo,
      pisada_pe_direito: peDireito,
    }
    if (anexo) {
      dados['anexo'] = anexo
    }

    for (const name in dados) {
      formData.append(name, dados[name])
    }

    try {
      setLoading(true)
      const response = await fetch(
        URL_RESULTADO(language),
        requestUpload(formData)
      )
      const data = await response.json()
      const { hashid } = data

      if (!response.ok && response.status === 422) {
        setErroCampos(data.errors || {})
        toast.error(data.message)
        return
      }

      if (!response.ok) {
        throw new Error(data.message)
      }

      const ditoIdentifyData = {
        ...dadosCliente,
        pe_esquerdo: peEsquerdo,
        pe_direito: peDireito,
      }

      ditoIdentify(ditoIdentifyData)

      handleResetForm()
      setErroCampos({})
      if (tipoTeste === TIPO_TESTE_NUMERACAO) {
        toast.success(data.message)
      } else {
        navigate(`/${language}/foot-id/resultado`, {
          state: { hashid, desconto },
        })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  function handleResetForm() {
    setDadosCliente({
      nome: '',
      email: '',
      genero: '',
      data_nascimento: '',
      telefone: '',
      numero_calcado: '',
    })
    //setTipoTeste('')
    setIdCliente('')
    setPeDireito('')
    setPeEsquerdo('')
    setCodigoVendedor('')
    setAnexo(null)
    //setFilialId(undefined)
  }

  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Layout>
        <SEO
          title="FOOT ID"
          description="Deixe seus pés falarem: Sua pisada pode definir tudo sobre você e a sua corrida"
        />

        <BannerFootID
          texto={`área <br /> <strong>de cadastro <br /> do vendedor</strong>`}
          src={bannerVendedor}
        />

        <S.Agendamento>
          <S.FormAgendamento
            enctype="multipart/form-data"
            onSubmit={handleSubmit}
          >
            <h1>dados pessoais do cliente</h1>
            <S.WrapperFormAgendamento>
              <div className="item">
                <Label texto="tipo_teste">tipo de teste</Label>
                <span>
                  <Select
                    id="tipo_teste"
                    value={tipoTeste || ''}
                    onChange={handleChangeFormTipoTeste}
                    className={erroCampos.tipoTeste && 'erro'}
                  >
                    <Option value="">SELECIONAR</Option>
                    {TIPOS_TESTE.map((val) => (
                      <Option key={`tipo-teste-${val}`} value={val}>
                        {TIPOS_TESTE_LABEL[val]}
                      </Option>
                    ))}
                  </Select>
                </span>
                {erroCampos.tipo_teste && (
                  <ErrorMessage mensagem={erroCampos.tipo_teste} />
                )}
              </div>
              <div className="item item--full item--busca">
                <Label texto="id_cliente">id cliente</Label>
                <span>
                  <Input
                    type="text"
                    id="id_cliente"
                    placeholder="Digite aqui o ID do cliente"
                    className="id_cliente"
                    value={idCliente}
                    onChange={({ target }) => setIdCliente(target.value)}
                    disabled={tipoTeste !== TIPO_TESTE_PISADA}
                  />
                  <Button
                    onClick={handleBuscarCliente}
                    disabled={tipoTeste !== TIPO_TESTE_PISADA}
                  >
                    {loadingCliente ? (
                      <>
                        <CircularProgress size={25} className="circleLoading" />
                      </>
                    ) : (
                      <FaSearch />
                    )}
                  </Button>
                </span>
              </div>
              <div className="item">
                <Label texto="nome">Nome</Label>
                <Input
                  type="text"
                  id="nome"
                  placeholder="Digite aqui o nome do cliente"
                  value={dadosCliente.nome || ''}
                  onChange={handleChangeForm}
                  className={erroCampos.nome && 'erro'}
                />
                {erroCampos.nome && <ErrorMessage mensagem={erroCampos.nome} />}
              </div>
              <div className="item">
                <Label texto="codigo_vendedor">código do vendedor</Label>
                <Input
                  type="text"
                  id="codigo_vendedor"
                  placeholder="Digite aqui seu código de vendedor"
                  value={codigoVendedor}
                  onChange={({ target }) => setCodigoVendedor(target.value)}
                  className={erroCampos.codigo_vendedor && 'erro'}
                />
                {erroCampos.codigo_vendedor && (
                  <ErrorMessage mensagem={erroCampos.codigo_vendedor} />
                )}
              </div>
              <div className="item">
                <Label texto="genero">Gênero</Label>
                <Select
                  id="genero"
                  value={dadosCliente.genero || ''}
                  onChange={handleChangeForm}
                  className={erroCampos.genero && 'erro'}
                >
                  <Option value="">SELECIONAR</Option>
                  {GENEROS.map((val) => (
                    <Option key={`genero-${val}`} value={val}>
                      {val}
                    </Option>
                  ))}
                </Select>
                {erroCampos.genero && (
                  <ErrorMessage mensagem={erroCampos.genero} />
                )}
              </div>
              <div className="item">
                <Label texto="email">E-mail</Label>
                <Input
                  type="email"
                  id="email"
                  placeholder="Digite aqui o e-mail do cliente"
                  value={dadosCliente.email || ''}
                  onChange={handleChangeForm}
                  className={erroCampos.email && 'erro'}
                />
                {erroCampos.email && (
                  <ErrorMessage mensagem={erroCampos.email} />
                )}
              </div>
              <div className="item">
                <Label texto="telefone">Telefone</Label>
                <InputMask
                  type="tel"
                  id="telefone"
                  placeholder="Digite aqui o telefone do cliente"
                  mask={maskTelefone}
                  className={cx('mask-input', { erro: erroCampos.telefone })}
                  value={dadosCliente.telefone || ''}
                  onChange={handleChangeForm}
                />
                {erroCampos.telefone && (
                  <ErrorMessage mensagem={erroCampos.telefone} />
                )}
              </div>
              <div className="item">
                <Label texto="numero_calcado">Número do calçado</Label>
                <Input
                  type="text"
                  id="numero_calcado"
                  placeholder="Digite aqui o número do calçado do cliente"
                  value={dadosCliente.numero_calcado || ''}
                  onChange={handleChangeForm}
                  className={erroCampos.numero_calcado && 'erro'}
                />
                {erroCampos.numero_calcado && (
                  <ErrorMessage mensagem={erroCampos.numero_calcado} />
                )}
              </div>
              <div className="item">
                <Label texto="data_nascimento">Data de Nascimento</Label>
                <Input
                  type="date"
                  id="data_nascimento"
                  placeholder=""
                  value={dadosCliente.data_nascimento || ''}
                  onChange={handleChangeForm}
                  className={erroCampos.data_nascimento && 'erro'}
                />
                {erroCampos.data_nascimento && (
                  <ErrorMessage mensagem={erroCampos.data_nascimento} />
                )}
              </div>
              <div className="item">
                <Label texto="loja_id">loja</Label>
                <Select
                  id="loja_id"
                  value={filialId}
                  onChange={({ target }) => setFilialId(Number(target.value))}
                  className={erroCampos.loja_id && 'erro'}
                  disabled={tipoTeste === ''}
                >
                  <Option value="">SELECIONAR FILIAL</Option>
                  {listaFiliaisUtilizada.map((filial) => (
                    <Option key={filial.nome} value={filial.id}>
                      {filial.nome} - {filial.endereco} - {filial.numero}
                    </Option>
                  ))}
                </Select>
                {erroCampos.loja_id && (
                  <ErrorMessage mensagem={erroCampos.loja_id} />
                )}
              </div>
              <div className="item">
                <Label texto="anexo">subir arquivo</Label>
                <S.Upload onClick={() => hiddenFileInput.current.click()}>
                  <input
                    type="file"
                    name="anexo"
                    id="anexo"
                    ref={hiddenFileInput}
                    onChange={({ target }) => setAnexo(target.files[0])}
                  />
                  {anexo && anexo.name}
                  <S.BotaoUpload>procurar arquivo</S.BotaoUpload>
                </S.Upload>
                {erroCampos.anexo && (
                  <ErrorMessage mensagem={erroCampos.anexo} />
                )}
              </div>
              <div className="item item--duplo">
                {tipoTeste === TIPO_TESTE_PISADA && (
                  <>
                    <span>
                      <Label texto="pisada_pe_esquerdo">PÉ ESQUERDO</Label>
                      <Select
                        id="pisada_pe_esquerdo"
                        value={peEsquerdo}
                        onChange={({ target }) => setPeEsquerdo(target.value)}
                        className={erroCampos.pisada_pe_esquerdo && 'erro'}
                      >
                        <Option value="">SELECIONE A PISADA</Option>
                        {PISADAS.map((val) => (
                          <Option key={`genero-${val}`} value={val}>
                            {val}
                          </Option>
                        ))}
                      </Select>
                      {erroCampos.pisada_pe_esquerdo && (
                        <ErrorMessage
                          mensagem={erroCampos.pisada_pe_esquerdo}
                        />
                      )}
                    </span>
                    <span>
                      <Label texto="pisada_pe_direito">PÉ DIREITO</Label>
                      <Select
                        id="pisada_pe_direito"
                        value={peDireito}
                        onChange={({ target }) => setPeDireito(target.value)}
                        className={erroCampos.pisada_pe_direito && 'erro'}
                      >
                        <Option value="">SELECIONE A PISADA</Option>
                        {PISADAS.map((val) => (
                          <Option key={`genero-${val}`} value={val}>
                            {val}
                          </Option>
                        ))}
                      </Select>
                      {erroCampos.pisada_pe_direito && (
                        <ErrorMessage mensagem={erroCampos.pisada_pe_direito} />
                      )}
                    </span>
                  </>
                )}
              </div>

              <div className="item">
                <Button type="submit">
                  {loading ? (
                    <>
                      <CircularProgress size={25} className="circleLoading" />{' '}
                      Cadastrando
                    </>
                  ) : (
                    'Enviar'
                  )}
                </Button>
              </div>
            </S.WrapperFormAgendamento>
          </S.FormAgendamento>
        </S.Agendamento>
      </Layout>
    </>
  )
}

export default VendedorFootIdPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
